import React, { useContext } from "react"
import { useI18next, Trans, Link } from "gatsby-plugin-react-i18next"
import "twin.macro"
import { InViewportContext } from "../../contexts/InViewportContext"
import logo from "../../images/logo.svg"
import Icon from "../icon"

const Navigation = ({ path }) => {
  const { languages, language, originalPath } = useI18next()
  const { inViewport } = useContext(InViewportContext)

  return (
    <header>
      <div tw="fixed w-full z-50 pt-5 md:py-6 lg:py-7 bg-gray-50 px-6">
        <nav tw="flex items-center justify-between flex-wrap md:flex-nowrap">
          <ul tw="w-full md:w-min text-sm md:text-base font-bold uppercase flex justify-start space-x-6 items-stretch order-2 md:order-1 h-10 md:h-auto">
            <li tw="flex items-stretch">
              <Link
                tw="h-full flex items-center"
                to="/#info"
                title="Info"
              >
                <Trans>nav_info</Trans>
              </Link>
            </li>
            <li tw="flex items-stretch">
              <Link
                tw="h-full flex items-center"
                to="/#apply"
                title="Bewerben"
              >
                <Trans>nav_apply</Trans>
              </Link>
            </li>
            <li tw="flex items-stretch">
              <Link
                tw="h-full flex items-center"
                to="/#faq"
                title="Faq"
              >
                <Trans>nav_faq</Trans>
              </Link>
            </li>
            <li tw="flex items-stretch">
              <Link
                tw="h-full flex items-center"
                to="/#contact"
                title="Kontakt"
              >
                <Trans>nav_contact</Trans>
              </Link>
            </li>
            {languages.filter(lng => lng !== language).map(filteredLng => (
              <li tw="items-stretch" key={filteredLng}>
                <Link to={originalPath} language={filteredLng}>
                  <div tw="px-4 py-1 border-2 border-black">{filteredLng}</div>
                </Link>
              </li>
            ))}
          </ul>
          <Link
            to="/"
            tw="ml-auto md:ml-0 md:order-2 mb-3 mt-1 md:my-0 relative"
          >
            <div tw='border-2 border-black md:mb-3 opacity-0 transform -translate-y-4 transition-all delay-200 duration-1000 ease-out absolute -top-3 md:-top-4 w-full' className={inViewport ? "logo-border-top logo-animation" : "logo-border-top"}></div>
            <Icon svg={logo} tw="w-28 md:w-32 lg:w-36" />
          </Link>
        </nav>
      </div>

    </header>
  )
}

Navigation.propTypes = {}

export default Navigation
