import React from 'react'
import PropTypes from 'prop-types'
import tw from 'twin.macro'

const Fluid = ({ children, spaceB= false, spaceY= false, bgGray200= false, id, scrollOffset= false }) => {
  return (
    <div
      id={id}
      css={[
        tw`w-full`,
        spaceB && tw`pb-20 md:pb-24 lg:pb-28`, 
        spaceY && tw`py-20 md:py-24 lg:py-28`, 
        bgGray200 && tw`bg-gray-200`, 
        scrollOffset && tw`scrollOffset`,
      ]}
    >
      <div tw="max-w-8xl mx-auto px-5 sm:px-6 md:px-8 lg:px-16 xl:px-28">
        {children}
      </div>
    </div>
  )
}

Fluid.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Fluid
