import React from 'react'
import tw, { css } from 'twin.macro'
import { Global } from '@emotion/react'
import TwinGlobalStyles from '../utils/twinGlobalStyles'
import Navigation from '../components/navigation'
import Footer from '../components/footer'

export default function Layout({ children, location }) {
  return (
    <>
      <Global
        styles={css`
          body {
            ${tw`antialiased font-body`}
            color: #131319;
            background: #f6f7f7;
          }
          html {
            scroll-behavior: smooth;
            @media (min-width: 1240px) {
              font-size: calc(12.25px + 0.3vw);
              -webkit-marquee-increment: 0vw;
            }
            @media (min-width: 1920px) {
              font-size: 18.007px;
            }
          }
        `}
      />
      <TwinGlobalStyles />
      <Navigation path={location.pathname} />
        <main tw="pt-24 md:pt-12 lg:pt-0">{children}</main>
      <Footer />
    </>
  )
}
